import React, { useEffect, useState } from 'react';
import { Col, Row } from "react-bootstrap";

import Transfer from '../components/transfers/transfer';

const useIsClient = () => {
    const [isClient, setClient] = useState(false);
    const key = isClient ? "client" : "server";

    useEffect(() => {
        setClient(true);
    }, []);

    return { isClient, key };
};

function Tansfers() {
    const { isClient } = useIsClient();
    return (

        <Row className="justify-content-center">
            <Col sm={12} md={6} xl={8}>
                {isClient && <Transfer></Transfer>}
            </Col>
        </Row>
    );
}

export default Tansfers;
