import AutoNumeric from 'autonumeric';
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Button, Card, Col, Form, InputGroup, Modal, Row } from 'react-bootstrap';
import NumberFormat from 'react-number-format';

import NetworkContext from '../../context/network-context';
import { useInvestorHpayBalance } from '../../state/investor-info';
import { ContractAddresses, Tokens } from '../../web3/contracts/contracts';
import { useHpayExchangePrice } from '../../web3/exchange';
import TransferConfirm from "./transfer-confirm";
import { useApprove } from '../../web3/account';

function Transfer() {
    const { account, connected, isCorrectNetwork, pendingTransaction, executeTransaction } = useContext(NetworkContext);
    const { approve, isApproved } = useApprove(ContractAddresses.HPAY, account, ContractAddresses.TRANSFER);

    const baseInput = useRef(null);
    const [numericHandle, setNumericHandle] = useState(null);
    const { hpayBalance } = useInvestorHpayBalance();
    const [isValid, setIsValid] = useState(false);
    const [receiver, setReceiver] = useState();
    const [amount, setAmount] = useState(0);
    const [warning, setWarning] = useState();
    const [showConfirm, setShowConfirm] = useState();
    const [modalStatus, setModalStatus] = useState(null);
    const [usdAmount, setUsdAmount] = useState(null);
    const [price, setPrice] = useState(0);
    const fetchRates = useHpayExchangePrice();

    useEffect(() => {
        fetchRates(Tokens.HPAY, Tokens.BUSD).then(setPrice);
    }, [setPrice, fetchRates]);

    const handleClose = useCallback(() => {
        if (pendingTransaction) {
            return;
        }
        setShowConfirm(false);
    }, [setShowConfirm, pendingTransaction]);

    useEffect(() => {
        if (amount > hpayBalance) {
            setWarning('Insufficient balance');
            setIsValid(false);
            return;
        }

        setWarning(null);
        setIsValid(receiver && receiver.length === 42);
    }, [receiver, hpayBalance, amount]);

    useEffect(() => {
        setUsdAmount(price * amount);
    }, [price, amount, setUsdAmount]);


    const handleReceiverUpdate = useCallback((e) => {
        const address = e.target.value;
        setReceiver(address);
    }, [setReceiver]);

    const handleAmountUpdate = useCallback(() => {

        if (!numericHandle) {
            return;
        }
        setAmount(numericHandle.getNumber());
    }, [setAmount, numericHandle]);

    const handleTransfer = useCallback((e) => {
        setModalStatus({
            amount,
            receiver,
            onConfirm: async () => {
                setShowConfirm(false);
            }
        });
        setShowConfirm(true);
    }, [setModalStatus, setShowConfirm, amount, receiver]);

    const handleApprove = async () => {
        const tx = async () => await approve();

        await executeTransaction({
            message: 'Approving',
            tx
        });
    };


    useEffect(() => {
        const handle = new AutoNumeric(baseInput.current, {
            decimalPlaces: 8,
            minimumValue: 0,
            allowDecimalPadding: false,
            showWarnings: false,
            emptyInputBehavior: 0,
            decimalCharacterAlternative: ','
        });

        setNumericHandle(handle);

    }, [baseInput, setNumericHandle]);

    const setMax = useCallback(() => {
        if (!numericHandle) {
            return;
        }
        numericHandle.set(hpayBalance);
        setAmount(hpayBalance - 0.0001);
        setUsdAmount(price * hpayBalance);
    }, [numericHandle, hpayBalance, price]);

    return (
        <>
            <Card >
                <Card.Body>
                    <Row className="align-items-center">
                        <Col md={12} className="text-lg-start mb-3">
                            <h3 className="mb-0 text-white text-center text-lg-start">Send HedgePay</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Form>
                                <Row className="justify-content-center no-gutters">

                                    <Form.Group as={Col} md="12" className="input-control mb-4" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">
                                                Receiver Address
                                            </Form.Label>
                                        </div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                className="staking-input mb-0"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="text"
                                                maxLength={42}
                                                name="addressInput"
                                                onChange={handleReceiverUpdate}
                                            />
                                        </InputGroup>
                                    </Form.Group>


                                    <Form.Group as={Col} md="12" className="input-control" controlId="validationFormik01">
                                        <div className="d-flex justify-content-between">
                                            <Form.Label className="balance-label">Spending $ <NumberFormat
                                                value={usdAmount} decimalScale={2} displayType={'text'} thousandSeparator={true} />
                                            </Form.Label>
                                            <Form.Label className="text-end balance-label">
                                                Balance <NumberFormat value={hpayBalance || 0} decimalScale={4} displayType={'text'} thousandSeparator={true} />
                                            </Form.Label>
                                        </div>
                                        <InputGroup className="mb-0">
                                            <Form.Control
                                                className="staking-input mb-0"
                                                type="text"
                                                autoComplete="off"
                                                inputMode="decimal"
                                                ref={baseInput}
                                                name="baseInput"
                                                onChange={handleAmountUpdate}
                                            />
                                            <InputGroup.Text className="staking-input-append mb-0">
                                                <span
                                                    role="button" tabIndex={0}
                                                    onKeyPress={setMax}
                                                    onClick={setMax} className="staking-input-max clickable">
                                                    MAX
                                                </span>
                                                HPAY </InputGroup.Text>
                                        </InputGroup>
                                    </Form.Group>



                                    <Form.Group as={Col} md="12" className="mt-4 px-0 mb-0 text-center">
                                        {isApproved ? <Button disabled={!connected || !isValid || !isCorrectNetwork || !!pendingTransaction}
                                            onClick={handleTransfer}
                                            className="mb-2 w-100">Transfer</Button> : <Button disabled={!connected || !isValid || !isCorrectNetwork || !!pendingTransaction}
                                                onClick={handleApprove}
                                                className="mb-2 w-100" >Approve</Button>}


                                        {connected && isCorrectNetwork && warning && <p className="mb-0">{warning}</p>}

                                    </Form.Group>
                                </Row>
                            </Form>
                        </Col>

                    </Row>
                </Card.Body>
            </Card >


            <Modal
                className="stake-modal"
                aria-labelledby="contained-modal-title-vcenter"
                centered show={!!showConfirm} onHide={handleClose} >

                <Modal.Body>
                    <TransferConfirm  {...modalStatus}></TransferConfirm>
                </Modal.Body>

            </Modal>
        </>
    );
}

export default Transfer;
